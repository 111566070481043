@import url('https://fonts.googleapis.com/css?family=Raleway:400,900');

@font-face {
  font-family: 'Segoe Script';
  src: url('../fonts/SegoeScript.eot');
  src: url('../fonts/SegoeScript.eot?#iefix') format('embedded-opentype'),
  url('../fonts/SegoeScript.woff') format('woff'),
  url('../fonts/SegoeScript.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
// Fonts
$raleway: "Raleway", sans-serif;
$segoe: "Segoe Script", sans-serif;

// Colors
$light-grey: #DEE7EA;
$white: #FFFFFF;
$orange: #F37622;
$dark-orange: #F05B25;
$grey: #4D4D4D;
$dark-grey: #333333;
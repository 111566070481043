// Fonts
@import "fonts";
// Variables
@import "variables";
// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

body, html {
  width: 100%;
  height: 100%;
}

body {
  background: $light-grey url('../img/background-pattern.png');
}

body.category-page {
  background: $white;
}

.container {
  max-width: 940px;
}

.welcome {
  text-align: center;

  header {
    margin-top: 180px;
    @media (max-width: 768px) {
      margin-top: 100px;
    }
    img {
      max-width: 100%;
      margin-bottom: 34px;
    }
  }

  main {
    margin-bottom: 91px;
    @media (max-width: 768px) {
      margin-bottom: 40px;
    }
    p {
      font-family: $segoe;
      font-size: 33px;
      line-height: 40px;
      color: $grey;
      margin-bottom: 31px;
      @media (max-width: 768px) {
        font-size: 25px;
        line-height: 32px;
      }
    }
    .button {
      @media (max-width: 768px) {
        padding: .5em;
        font-size: 25px;
      }
    }
  }
}

.button {
  font-family: $raleway;
  font-weight: 900;
  text-transform: uppercase;
  border-radius: .3em;
  border: none;
  transition: background-color .5s ease, color .5s ease;
  display: inline-block;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.3);
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }
}

.category-alegria {
  .answer:hover {
    background: #EA2227;
  }
  .answer span {
    color: #EA2227;
  }
  main {
    .button {
      background-color: #EA2227;
      color: $white;
      &:hover {
        color: #EA2227;
        background: $white;
      }
    }
    li:before {
      color: #EA2227;
    }
  }
  header {
    background: url('../img/sections-header-1.png') bottom center;
  }
}

.category-balance {
  .answer:hover {
    background: #A658A1;
  }
  .answer span {
    color: #A658A1;
  }
  main {
    .button {
      background-color: #A658A1;
      color: $white;
      &:hover {
        color: #A658A1;
        background: $white;
      }
    }
    li:before {
      color: #A658A1;
    }
  }
  header {
    background: url('../img/sections-header-2.png') bottom center;
  }
}

.category-finanzas {
  .answer:hover {
    background: #7BA93E;
  }
  .answer span {
    color: #7BA93E;
  }
  main {
    .button {
      background-color: #7BA93E;
      color: $white;
      &:hover {
        color: #7BA93E;
        background: $white;
      }
    }
    li:before {
      color: #7BA93E;
    }
  }
  header {
    background: url('../img/sections-header-3.png') bottom center;
  }
}

.category-proteccion {
  .answer:hover {
    background: #F89E1E;
  }
  .answer span {
    color: #F89E1E;
  }
  main {
    .button {
      background-color: #F89E1E;
      color: $white;
      &:hover {
        color: #F89E1E;
        background: $white;
      }
    }
    li:before {
      color: #F89E1E;
    }
  }
  header {
    background: url('../img/sections-header-4.png') bottom center;
  }
}

.category {
  .category-button {
    padding-top: 50px;
    padding-bottom: 50px;
    text-align: center;
  }
  main {
    padding-top: 90px;
    ul {
      padding: 0;
      column-count: 2;
      column-gap: 40px;
      @media (max-width: 992px) {
        column-count: 1;
      }
    }
    li {
      color: #648495;
      font-size: 24px;
      line-height: 33px;
      list-style-type: none;
      &:before {
        content: '\2022';
        padding-right: 8px;
      }
      @media (max-width: 992px) {
        font-size: 20px;
        line-height: 29px;
      }
      @media (max-width: 768px) {
        font-size: 18px;
        line-height: 27px;
      }
    }
  }
  header {
    padding-bottom: 25px;
    .image {
      text-align: center;
    }
    .image-container {
      position: relative;
    }
    .category-data {
      img {
        position: absolute;
        transform: rotate(-5deg);
        @media (max-width: 992px) {
          display: none;
        }
      }
      h1 {
        color: $white;
        font-family: $segoe;
        font-size: 40px;
        @media (max-width: 992px) {
          font-size: 33px;
        }
        @media (max-width: 768px) {
          font-size: 25px;
        }
      }
      h2 {
        color: $white;
        font-family: $raleway;
        font-size: 28px;
        text-transform: uppercase;
        margin-top: -2px;
        line-height: 35px;
        @media (max-width: 992px) {
          font-size: 24px;
          line-height: 31px;
        }
        @media (max-width: 768px) {
          font-size: 20px;
          line-height: 26px;
        }
      }
    }
    span {
      top: 25px;
      right: 0;
      float: right;
      color: #FF6600;
      text-transform: uppercase;
      background: #FFFFFF;
      font-size: 14px;
      font-family: $raleway;
      font-weight: 900;
      display: inline-block;
      position: absolute;
      padding: 4px 13px;
      @media (max-width: 992px) {
        position: relative;
        float: none;
        top: auto;
        right: auto;
        margin-top: 20px;
      }
    }
  }
}

.result {
  main {
    min-height: 300px;
    form {
      overflow: hidden;
      background-image: url(../img/form-pattern.png), url(../img/form-pattern.png);
      background-position: top left, bottom left;
      background-repeat: no-repeat;
      padding: 30px 0;
      input {
        display: block;
        width: 100%;
        height: 59px;
        font-family: $raleway;
        font-size: 24px;
        padding: 0 20px;
        border-radius: 10px;
        margin-bottom: 10px;
        border: none;
      }
      label {
        margin: 0;
        font-size: 48px;
        line-height: 61px;
        color: $orange;
        font-family: $raleway;
        font-weight: 900;
        @media (max-width: 992px) {
          font-size: 37px;
        }
        @media (max-width: 768px) {
          font-size: 30px;
          line-height: 40px;
        }
      }
    }
    padding-top: 50px;
    padding-bottom: 30px;
    .button {
      margin-bottom: 20px;
    }
  }
  .result-text {
    text-align: center;
    color: $white;
    @media (max-width: 992px) {
      text-align: left;
    }
    h1 {
      font-size: 92px;
      font-family: $segoe;
      margin-top: 50px;
      @media (max-width: 992px) {
        font-size: 70px;
      }
      @media (max-width: 768px) {
        font-size: 50px;
      }
    }
    p {
      padding-bottom: 10px;
      font-size: 36px;
      font-family: $raleway;
      text-transform: uppercase;
      @media (max-width: 992px) {
        font-size: 30px;
      }
      @media (max-width: 768px) {
        font-size: 20px;
      }
    }
  }

  header {
    background: url('../img/sections-header.png') bottom left;
    padding-bottom: 25px;
    .image {
      text-align: center;
    }
    .image-container {
      position: relative;
    }
    span {
      top: 25px;
      right: 0;
      float: right;
      color: #FF6600;
      text-transform: uppercase;
      background: #FFFFFF;
      font-size: 14px;
      font-family: $raleway;
      font-weight: 900;
      display: inline-block;
      position: absolute;
      padding: 4px 13px;
      @media (max-width: 992px) {
        position: relative;
        float: none;
        top: auto;
        right: auto;
        margin-top: 20px;
      }
    }
  }
}

.configuration,
.cards {
  header {
    background: url('../img/sections-header.png') bottom left;
    padding-bottom: 25px;
    .image {
      text-align: center;
    }
    .image-container {
      position: relative;
    }
    h1 {
      color: $white;
      font-family: $segoe;
      font-size: 40px;
      @media (max-width: 768px) {
        font-size: 30px;
      }
    }
    p {
      font-weight: 400;
      font-size: 28px;
      line-height: 35px;
      text-transform: uppercase;
      font-family: $raleway;
      color: $white;
      @media (max-width: 768px) {
        font-size: 20px;
        line-height: 1.3;
      }
    }
  }
}

.daleplay-footer {
  float: left;
  @media (max-width: 768px) {
    float: none;
  }
  padding-top: 15px;
}

.tarjetanaranja-footer {
  float: right;
  @media (max-width: 768px) {
    float: none;
  }
}

.cards {
  .cards-list {
    margin-top: 80px;
    margin-bottom: 35px;

    .card {
      text-align: center;
      transition: transform .5s ease;
      img {
        display: inline-block;
      }
      &:hover {
        transform: scale(1.2) rotate(7deg);
        z-index: 1;
        position: relative;
      }
      margin-bottom: 30px;
      display: block;
    }
  }
  header {
    span {
      top: 25px;
      right: 0;
      float: right;
      color: #FF6600;
      text-transform: uppercase;
      background: #FFFFFF;
      font-size: 14px;
      font-family: $raleway;
      font-weight: 900;
      display: inline-block;
      position: absolute;
      padding: 4px 13px;
      @media (max-width: 992px) {
        position: relative;
        float: none;
        top: auto;
        right: auto;
        margin-top: 20px;
      }
    }
    p {
      font-size: 26px;
      @media (max-width: 768px) {
        font-size: 20px;
        line-height: 1.3;
      }
    }
  }
}

.question {
  header {
    padding-bottom: 25px;
    min-height: 290px;
    .image {
      text-align: center;
    }
    .image-container {
      position: relative;
    }
    .question-data {
      img {
        position: absolute;
        transform: rotate(-5deg);
        @media (max-width: 992px) {
          display: none;
        }
      }
      .question {
        color: $white;
        font-weight: 900;
        font-family: $raleway;
        font-size: 28px;
        text-transform: uppercase;
        line-height: 31px;
        margin-top: 37px;
        padding-bottom: 30px;
        @media (max-width: 992px) {
          font-size: 24px;
        }
      }
    }
    span {
      top: 25px;
      right: 0;
      float: right;
      color: #FF6600;
      text-transform: uppercase;
      background: #FFFFFF;
      font-size: 14px;
      font-family: $raleway;
      font-weight: 900;
      display: inline-block;
      position: absolute;
      padding: 4px 13px;
      @media (max-width: 992px) {
        position: relative;
        float: none;
        top: auto;
        right: auto;
        margin-top: 20px;
      }
    }
  }
  .button-box {
    padding-top: 30px;
    text-align: center;
  }
  .answers {
    padding-top: 60px;
  }
  .answer {
    p {
      color: #333333;
      font-size: 20px;
      font-family: $raleway;
    }
    padding: 0 15px 15px;
    border-radius: 10px;
    background: #E6E6E6;
    min-height: 270px;
    @media (max-width: 768px) {
      min-height: auto;
    }
    text-align: center;
    display: block;
    box-shadow: 0px 0px 20px rgba(14, 14, 14, 0.4);
    transition: all .4s;
    margin-bottom: 20px;
    &:hover {
      text-decoration: none;
      transform: scale(1.08);
      span, p {
        color: $white;
      }
    }
    span {
      font-size: 53px;
      font-family: $raleway;
      font-weight: 900;
    }
  }
}

.cards, .category, .question, .result {
  footer {
    background-color: $white;
    border-top-left-radius: 100% 90px;
    border-top-right-radius: 100% 90px;
    padding: 35px 0 15px;
    max-width: 1000px;
    margin: 40px auto 0;
    box-shadow: 0 0 20px rgba(14, 14, 14, 0.57);
    img {
      margin: 15px 0;
      display: inline-block;
    }
    @media (max-width: 768px) {
      text-align: center;
    }
  }
}

.configuration {
  form {
    display: none;
    .holder {
      background: $white;
      border-radius: 10px;
      padding: 50px 0 35px;
      margin-top: 20px;
    }
    .form-group {
      padding-left: 20px;
      background: url(../img/bullet.png) 0px 4px no-repeat;
    }
    .button-holder {
      text-align: center;
      position: relative;
      top: -18px;
    }
    label {
      text-transform: uppercase;
      margin-bottom: 7px;
      color: $orange;
      font-weight: 900;
      font-size: 20px;
      line-height: 1.3;
      font-family: $raleway;
    }
  }

}

.button.big {
  padding: .2em 1.5em;
  font-size: 37px;
}

.button.final {
  display: block;
  text-align: center;
  font-size: 25px;
  padding: 19px 0;
}

.button.medium {
  padding: .5em 1.5em;
  font-size: 24px;
}

.button.small {
  padding: .4em 1.3em;
  font-size: 14px;
}

.button.orange {
  background-color: $orange;
  color: $white;
  &:hover {
    color: $orange;
    background: $white;
  }
}

.button.form {
  font-size: 25px;
  height: 59px;
  display: block;
  width: 100%;
}

.button.blue {
  background-color: #648495;
  color: $white;
  &:hover {
    color: #648495;
    background: $white;
  }
}